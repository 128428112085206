import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {catchError, forkJoin, map, of, switchMap} from 'rxjs';

import {ApplicationApiService, CountryApiService} from '@core/services';

import {StaticDataActions} from './static-data.actions';

@Injectable()
export class StaticDataEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly applicationApiService = inject(ApplicationApiService);
  private readonly countryApiService = inject(CountryApiService);

  loadStaticData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StaticDataActions.loadStaticData),
      switchMap(() => forkJoin([
          this.applicationApiService.getList().pipe(
            catchError((err) => {
              console.error('Failed to fetch applications.', err);
              return of([]);
            })
          ),
          this.countryApiService.getList().pipe(
            catchError((err) => {
              console.error('Failed to fetch countries.', err);
              return of([]);
            })
          )
        ])
      ),
      map(([applications, countries]) => StaticDataActions.loadStaticDataSuccess(countries, applications))
    );
  });
}
