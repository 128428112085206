import {createReducer, on} from '@ngrx/store';
import {produce} from 'immer';

import {UserInfoActions} from './user-info.actions';
import {userInfoInitialState} from './user-info.state';

export const userInfoReducer = createReducer(
    userInfoInitialState,
    on(UserInfoActions.loadUserProfileSuccess, (state, {userProfile, keycloakPermissions}) =>
      produce(state, (draft) => {
        draft.userProfile = userProfile;
        draft.keycloakPermissions = keycloakPermissions;
      })
    ),
  )
;

