import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input
} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {Store} from '@ngrx/store';

import {OrganizationStatus} from '@features/organizations/models';
import {OrganizationsActions, organizationsFeature} from '@features/organizations/store';
import {BtnPendingDirective, LetDirective} from '@shared/directives';

@Component({
  selector: 'aps-organization-deactivate',
  templateUrl: './organization-deactivate.component.html',
  standalone: true,
  imports: [CommonModule, BtnPendingDirective, MatButton, LetDirective],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationDeactivateComponent {
  private readonly store = inject(Store);
  organizationStatus = input();
  isActiveOrganization = computed(() => this.organizationStatus() === OrganizationStatus.active);
  pending$ = this.store.select(organizationsFeature.selectIsOrganizationUpdateStatusPending);

  deactivate(): void {
    this.store.dispatch(OrganizationsActions.updateOrganizationStatus(OrganizationStatus.inactive));
  }

  activate(): void {
    this.store.dispatch(OrganizationsActions.updateOrganizationStatus(OrganizationStatus.active));
  }
}
