import {HttpClient, HttpParams} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {map, Observable, switchMap} from 'rxjs';

import {ListResponse, Maybe, Pagination, ValidatedResponse} from '@core/common';
import {Project, ProjectCreateReq, ProjectUpdateReq} from '@features/projects/models';
import {AgGridSort, AgGridSortState, ProjectFilter} from '@shared/models';

@Injectable({ providedIn: 'root' })
export class ProjectApiService {
  private readonly httpClient = inject(HttpClient);

  getProjects(pagination: Pagination, sorting: Maybe<AgGridSortState[]> = null, filter: Maybe<ProjectFilter> = null): Observable<ListResponse<Project>> {
    let params = new HttpParams({
      fromObject: {
        ...(filter?.name ? { name: filter.name } : null),
        ...(filter?.isActive ? { isActive: filter.isActive } : null),
        pageNumber: pagination.pageIndex + 1,
        pageSize: pagination.pageSize
      }
    });

    if (sorting?.length) {
      params = sorting.reduce((parms, el) => {
        const sortingOrder = el.order === AgGridSort.DESC ? 'OrderByDescending' : 'OrderBy';
        return parms.append('PropertyNames', `${this.mapSortPropertyToDtoProperty(el.propertyName!)},${sortingOrder}`);
      }, params);
    }

    return this.httpClient.get<ListResponse<Project>>(`${environment.baseURL}/projects`, { params });
  }

  searchByName(name: string): Observable<ListResponse<Project>> {
    const params: any = {
      name
    };

    return this.httpClient.get<ListResponse<Project>>(`${environment.baseURL}/projects`, { params });
  }

  searchByRefCode(referenceCode: string): Observable<ListResponse<Project>> {
    const params: any = {
      referenceCode
    };

    return this.httpClient.get<ListResponse<Project>>(`${environment.baseURL}/projects`, { params });
  }

  getById(id: number): Observable<Project> {
    return this.httpClient.get<Project>(`${environment.baseURL}/projects/${id}`);
  }

  create(project: ProjectCreateReq): Observable<ValidatedResponse<number>> {
    return this.httpClient.post<ValidatedResponse<number>>(`${environment.baseURL}/projects`, project);
  }

  update(id: number, project: ProjectUpdateReq): Observable<ValidatedResponse> {
    return this.httpClient.put<ValidatedResponse>(`${environment.baseURL}/projects/${id}`, project);
  }

  updateStatus(id: number, isActive: boolean): Observable<void> {
    return this.httpClient.put<void>(`${environment.baseURL}/projects/${id}/status`, { isActive });
  }

  uploadFile(file: File): Observable<string> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post<{ value: string }>(`${environment.baseURL}/projects/files/blueprint`, formData).pipe(
      map(v => v.value),
    );
  }

  getProjectFileByName(projectId: number, fileName: string): Observable<Blob> {
    return this.httpClient.get(`${environment.baseURL}/projects/${projectId}/files/${fileName}`, { responseType: 'blob' });
  }

  updateFile(projectId: number, file: File): Observable<string> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.patch<void>(`${environment.baseURL}/projects/${projectId}/file`, formData).pipe(
      switchMap(() => this.getById(projectId)),
      map(project => project.photo)
    );
  }

  uploadFiberRouteFile(projectId: number, file: File): Observable<Project> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    const uploadMethod$ = file.type === 'text/csv' ?
      this.httpClient.post<void>(`${environment.baseURL}/projects/${projectId}/assetRoute/import/csv`, formData) :
      this.httpClient.patch<void>(`${environment.baseURL}/projects/${projectId}/kmzKmlFile`, formData);

    return uploadMethod$.pipe(
      switchMap(() => this.getById(projectId))
    );
  }

  private mapSortPropertyToDtoProperty(propertyName: string): string {
    if (propertyName === 'poi') {
      return 'pois';
    }
    if (propertyName === 'organization.name') {
      return 'organization';
    }
    if (propertyName === 'serialNumbers') {
      return 'signalType';
    }
    if (propertyName === 'lastUpdatedDate') {
      return 'updatedProjects';
    }
    return propertyName;
  }
}
