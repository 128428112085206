import {inject, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {KeycloakEvent} from 'keycloak-angular/lib/core/interfaces/keycloak-event';
import {NgxPermissionsService} from 'ngx-permissions';
import {filter, map, switchMap, take, tap} from 'rxjs';

import {OrganizationInactiveNotificationComponent} from '@features/organizations/components';
import {UserPermissions, UserProfile, UserRole} from '@features/users/models';
import {UserApiService} from '@features/users/services';

import {StaticDataActions} from '../static-data';

import {UserInfoActions} from './user-info.actions';

@Injectable()
export class UserInfoEffects {
  private readonly actions$ = inject(Actions);
  private readonly matDialog = inject(MatDialog);
  private readonly keycloak = inject(KeycloakService);
  private readonly permissionsService = inject(NgxPermissionsService);
  private readonly userApiService = inject(UserApiService);


  init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserInfoActions.init),
      switchMap(() => this.keycloak.keycloakEvents$),
      filter((e: KeycloakEvent) => e.type === KeycloakEventType.OnReady),
      take(1),
      map(() => UserInfoActions.loadUserProfile()));
  });

  loadKeycloakUserProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserInfoActions.loadUserProfile),
      switchMap(() => this.userApiService.getMyProfile()),
      map((profile) => {
        const keycloakPermissions = this.keycloak.getUserRoles();
        return UserInfoActions.loadUserProfileSuccess(profile, keycloakPermissions)
      })
    );
  });

  setPermissions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserInfoActions.loadUserProfileSuccess),
      tap(({ userProfile, keycloakPermissions }) => {
        const permissions = [];
        permissions.push(...keycloakPermissions);
        if (!this.hasRole(UserRole.TECHNICIAN, keycloakPermissions)) {
          if (userProfile.assetMapping) {
            permissions.push(UserPermissions.ASSET_MAPPING);
          }
          if (userProfile.eventReporting) {
            permissions.push(UserPermissions.EVENT_REPORTING);
          }
        }
        if (this.hasRole(UserRole.PE, keycloakPermissions)) {
          this.checkUserOrganization(userProfile);
        }
        this.permissionsService.loadPermissions(permissions);
      }))
  }, { dispatch: false })

  loadStaticData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserInfoActions.loadUserProfileSuccess),
      map(() => StaticDataActions.loadStaticData())
    );
  });

  private hasRole(roleToCheck: UserRole, userRoles: string[]): boolean {
    return Boolean(userRoles.includes(roleToCheck));
  }

  private checkUserOrganization(profile: Partial<UserProfile>): void {
    const hasInactiveOrganizations = profile?.organizations?.some(org => !org.status);

    if (hasInactiveOrganizations) {
      this.showInactiveOrganizationDialog();
    }
  }

  private showInactiveOrganizationDialog(): void {
    this.matDialog.open(OrganizationInactiveNotificationComponent, {
      width: '512px',
      closeOnNavigation: false,
      disableClose: true
    });
  }
}
