import {ActionReducerMap} from '@ngrx/store';

import {AppState} from './core.state';
import {staticDataReducer} from './static-data';
import {userInfoReducer} from './user-info';

export const rootReducers: ActionReducerMap<AppState> = {
  staticData: staticDataReducer,
  userInfo: userInfoReducer,
}
