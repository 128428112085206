<div class="flex items-baseline">
  <form [formGroup]="form" autocomplete="off">
    <mat-form-field class="w-56 project-filter-form-field" appearance="outline">
      <input type="text" matInput placeholder="Search by name" formControlName="name">
      @if (form.get('name')?.value) {
        <mat-icon matSuffix aria-label="Clear" svgIcon="close" class="size-smd cursor-pointer" (click)="clearSearchByNameFilter()" />
      }
    </mat-form-field>
    <mat-checkbox color="primary"
                  class="ml-3"
                  formControlName="showActiveProjects">
      <span class="text-sm">Show active projects only</span>
    </mat-checkbox>
  </form>
</div>
