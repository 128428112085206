import {OrganizationsEffects} from '@features/organizations/store';

import {StaticDataEffects} from './static-data';
import {UserInfoEffects} from './user-info';

export const storeEffects = [
  UserInfoEffects,
  StaticDataEffects,
  OrganizationsEffects,
];
