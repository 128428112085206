<label [class.required]="required">{{ label }}</label>
<mat-form-field class="w-full" appearance="outline" floatLabel="always">
  @if (isRequestPending) {
    <mat-spinner matPrefix
                 [diameter]="16"
                 class="mx-3"
                 mode="indeterminate" />
  } @else {
    <mat-icon matPrefix class="color-medium" [svgIcon]="icon" />
  }
  <mat-select data-test="e2e-selector"
              [formControl]="formControl"
              [multiple]="multiple"
              [placeholder]="placeholder"
              [complete]="fetchedCount === total"
              (infiniteScroll)="getNextBatch()"
              (selectionChange)="selectionChange.emit()"
              apsMatSelectInfiniteScroll>
    @for (option of options$ | async; track option.id) {
      <mat-option data-test="e2e-selector-option" [disabled]="option?.isDisabled" [value]="option.id">{{ option.name | apsValueOrDefault }}</mat-option>
    }
  </mat-select>
</mat-form-field>
