import {inject, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {Store} from '@ngrx/store';
import {catchError, filter, iif, map, of, switchMap, tap} from 'rxjs';
import {take} from 'rxjs/operators';

import {DEFAULT_MODAL_WIDTH} from '@core/common';
import {OrganizationCreateComponent} from '@features/organizations/components';
import {OrganizationStatus} from '@features/organizations/models';
import {OrganizationApiService} from '@features/organizations/services';
import {OrganizationsActions} from '@features/organizations/store/organizations.actions';
import {organizationsFeature} from '@features/organizations/store/organizations.feature';
import {mapOrganizationsToListItems} from '@features/organizations/utils';
import {ConfirmActionDialogComponent} from '@shared/components';
import {ConfirmActionDialogData} from '@shared/models';
import {NotificationService} from '@shared/services';

import {selectApplications} from '../../../store/static-data';

@Injectable()
export class OrganizationsEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly organizationApi = inject(OrganizationApiService);
  private readonly notification = inject(NotificationService);
  private readonly dialog = inject(MatDialog);

  loadOrganizations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        OrganizationsActions.loadOrganizations,
        OrganizationsActions.createOrganizationSuccess,
        OrganizationsActions.updateOrganizationSuccess,
        OrganizationsActions.updateOrganizationStatusSuccess
      ),
      concatLatestFrom(() => [
        this.store.select(organizationsFeature.selectOrganizationsPagination),
        this.store.select(organizationsFeature.selectSortingState),
        this.store.select(organizationsFeature.selectOrganizationsListPreferences),
        this.store.select(selectApplications).pipe(
          filter(Boolean),
          map((applications) => new Map(applications.map(item => [item.id, item])))
        )
      ]),
      switchMap(([_, pagination, sortingState, prefs, applicationsMap]) => {
        return this.organizationApi.getOrganizations(pagination, prefs?.status!, sortingState).pipe(
          map((response) => OrganizationsActions.loadOrganizationsSuccess(mapOrganizationsToListItems(response, applicationsMap))),
          catchError((err) => {
            this.notification.error('Failed to fetch organizations. Please contact support.');
            console.error('Failed to fetch organizations!', err);
            return of(OrganizationsActions.loadOrganizationsFailure());
          })
        );
      })
    );
  });

  createOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationsActions.createOrganization),
      switchMap(({ req }) => this.organizationApi.create(req).pipe(
        map(() => {
          this.notification.success(`Created successfully`);
          return OrganizationsActions.createOrganizationSuccess();
        }),
        catchError((err) => {
          this.notification.error('Failed to create organizations. Please contact support.');
          console.error('Failed to create organizations!', err);
          return of(OrganizationsActions.createOrganizationFailure());
        })
      ))
    );
  });

  updateOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationsActions.updateOrganization),
      concatLatestFrom(() => this.store.select(organizationsFeature.selectOrganizationById)),
      switchMap(([{ req }, organization]) => this.organizationApi.update(organization?.id!, req).pipe(
        map(() => {
          this.notification.success(`Updated successfully`);
          return OrganizationsActions.updateOrganizationSuccess();
        }),
        catchError((err) => {
          this.notification.error('Failed to update organizations. Please contact support.');
          console.error('Failed to update organizations!', err);
          return of(OrganizationsActions.updateOrganizationFailure());
        })
      ))
    );
  });

  changeOrganizationStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationsActions.updateOrganizationStatus),
      concatLatestFrom(() => this.store.select(organizationsFeature.selectOrganizationById)),
      switchMap(([{ status }, organization]) => {
        const isActiveStatus = status === OrganizationStatus.active;
        const confirmActionDialogData: ConfirmActionDialogData = {
          title: `Organization will be ${isActiveStatus ? 'activated' : 'deactivated'}`,
          description: `Do you want to ${isActiveStatus ? 'activate' : 'deactivate'} organization?`
        };

        return this.dialog.open(ConfirmActionDialogComponent, {
          width: '504px',
          data: confirmActionDialogData
        }).afterClosed()
          .pipe(
            take(1),
            switchMap((value) => iif(
              () => Boolean(value),
              this.organizationApi.status(organization?.id!, status).pipe(
                map(() => {
                  this.notification.success(`Organization successfully ${isActiveStatus ? 'activated' : 'deactivated'}`);
                  return OrganizationsActions.updateOrganizationStatusSuccess(status);
                }),
                catchError((err) => {
                  this.notification.error('Failed to update organization status. Please contact support.');
                  console.error('Failed to update organization status!', err);
                  return of(OrganizationsActions.updateOrganizationStatusFailure());
                })
              ),
              of(OrganizationsActions.updateOrganizationStatusCancel())
            ))
          );
      })
    );
  });

  checkIfOrganizationPendingBeforePreviewDialogOpen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationsActions.openOrganizationPreviewDialog),
      concatLatestFrom(() => this.store.select(organizationsFeature.selectIsOrganizationByIdPending).pipe(
        filter(pending => !pending)
      )),
      map(([{ id }]) => OrganizationsActions.loadOrganizationById(id))
    );
  });

  loadOrganizationById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationsActions.loadOrganizationById),
      switchMap(({ id }) => this.organizationApi.getById(id).pipe(
        map((response) => OrganizationsActions.loadOrganizationByIdSuccess(response)),
        catchError((err) => {
          this.notification.error(`Failed to fetch organization data.`);
          console.error(`Failed to fetch organization. ID ${id}`, err);
          return of(OrganizationsActions.loadOrganizationByIdFailure());
        })
      ))
    );
  });

  closeOrganizationDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        OrganizationsActions.createOrganizationSuccess,
        OrganizationsActions.createOrganizationFailure,
        OrganizationsActions.updateOrganizationSuccess,
        OrganizationsActions.updateOrganizationFailure
      ),
      tap(() => this.dialog.closeAll())
    );
  }, { dispatch: false });

  openOrganizationUpdateDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationsActions.loadOrganizationByIdSuccess),
      switchMap(() => this.dialog.open(OrganizationCreateComponent, {
        width: DEFAULT_MODAL_WIDTH,
        closeOnNavigation: true
      }).afterClosed()),
      map(() => OrganizationsActions.clearOrganizationById())
    );
  });

  updateOrganizationsList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrganizationsActions.updateOrganizationsSorting, OrganizationsActions.updateOrganizationsPagination),
      map(() => OrganizationsActions.loadOrganizations())
    );
  });
}
