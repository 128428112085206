<div data-test="e2e-confirm-action-dialog">
  <h2 mat-dialog-title data-test="e2e-confirm-action-title">{{ confirmActionDialogData?.title }}</h2>
  <div mat-dialog-content data-test="e2e-confirm-action-description">
    <p>{{ confirmActionDialogData?.description }}</p>
  </div>
  <div mat-dialog-actions>
    <div class="left-side">
      <button mat-flat-button
              color="primary"
              class="mr-2"
              data-test="e2e-confirm-action-confirm-btn"
              (click)="confirm()">
        Confirm
      </button>
      <button mat-stroked-button
              data-test="e2e-confirm-action-cancel-btn"
              (click)="close()">Cancel
      </button>
    </div>
  </div>
</div>
