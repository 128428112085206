import {inject, Injectable} from '@angular/core';
import {FormGroup, NonNullableFormBuilder, Validators} from '@angular/forms';

import {OrganizationForm, OrganizationResponse} from '@features/organizations/models';
import {OrganizationApiService} from '@features/organizations/services';
import {ValidateNameNotTaken, ValidateRefCodeNotTaken} from '@features/organizations/utils/organization-create.helper';


@Injectable({ providedIn: 'root' })
export class OrganizationFormBuilder {
  private readonly fb = inject(NonNullableFormBuilder);
  private readonly organizationApiService = inject(OrganizationApiService);

  readonly organizationNameMinLength = 2;
  readonly organizationNameMaxLength = 256;
  readonly referenceCodeMaxLength = 256;
  readonly locationMaxLength = 256;
  readonly commentMaxLength = 512;

  buildForm(organization?: OrganizationResponse): FormGroup<OrganizationForm> {
    return this.fb.group<OrganizationForm>({
      organizationName: this.fb.control('',
        [
          Validators.required,
          Validators.minLength(this.organizationNameMinLength),
          Validators.maxLength(this.organizationNameMaxLength)
        ],
        [ValidateNameNotTaken.createValidator(this.organizationApiService, organization)]
      ),
      applicationIds: this.fb.control([]),
      referenceCode: this.fb.control('',
        [Validators.required, Validators.maxLength(this.referenceCodeMaxLength)],
        [ValidateRefCodeNotTaken.createValidator(this.organizationApiService, organization)]
      ),
      countryId: this.fb.control(null),
      location: this.fb.control('', [Validators.maxLength(this.locationMaxLength)]),
      comment: this.fb.control('', [Validators.maxLength(this.commentMaxLength)])
    });
  }
}
