import {FormControl} from '@angular/forms';

import {Maybe} from '@core/common';
import {UserBase} from '@features/users/models';

export interface Country {
  id: number;
  code: string;
  name: string;
}

export interface Application {
  id: number;
  shortFormTitle: string;
  longFormTitle: string;
}

export interface OrganizationResponse {
  activeProjects: number;
  applicationIds: number[];
  comment: string;
  countryId: number;
  createdBy: any;
  createdOn: string;
  id: number;
  location: string;
  modifiedBy: any;
  modifiedOn: string;
  organizationName: string;
  primaryContactUsers: UserBase[];
  referenceCode: string;
  status: OrganizationStatus;
}

export interface OrganizationForm {
  organizationName: FormControl<string>;
  applicationIds: FormControl<number[]>;
  referenceCode: FormControl<string>;
  countryId: FormControl<Maybe<number>>;
  location: FormControl<string>;
  comment: FormControl<string>;
}

export interface OrganizationListItemResponse {
  activeProjects: number;
  applicationIds: number[];
  id: number;
  isActive: boolean;
  location: string;
  country: string;
  organizationName: string;
}

export interface OrganizationListItem extends OrganizationListItemResponse {
  applications: Application[];
}

export interface OrganizationCreateUpdateReq {
  organizationName: string;
  applicationIds: number[];
  referenceCode: string;
  countryId: number;
  location: string;
  comment: string;
}

export interface OrganizationListPreferences {
  status: Maybe<OrganizationStatus>;
}

export enum OrganizationStatus {
  active = 'ACTIVE',
  inactive = 'INACTIVE'
}
