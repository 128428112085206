import {createActionGroup, emptyProps} from '@ngrx/store';

import {UserProfile} from '@features/users/models';


export const UserInfoActions = createActionGroup({
  source: 'Auth User',
  events: {
    'Init': emptyProps(),
    'Load user profile': emptyProps(),
    'Load user profile success': (userProfile: UserProfile, keycloakPermissions: string[]) => ({
      userProfile,
      keycloakPermissions,
    }),
    'Load user profile failure': emptyProps(),
  }
});
