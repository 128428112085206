import {createSelector} from '@ngrx/store';

import {AppState} from '../core.state';

export const selectStaticDataState = (state: AppState) => state.staticData;

export const selectCountries = createSelector(
  selectStaticDataState,
  (state) => state.countries
);

export const selectApplications = createSelector(
  selectStaticDataState,
  (state) => state.applications
);
