<div mat-dialog-title>
  <h2 class="flex items-center" data-test="e2e-entity-create-dialog-title">
    <mat-icon class="mr-4 color-medium" [svgIcon]="icon" />
    {{ title }}
  </h2>

  <button mat-icon-button
          mat-dialog-close
          class="mdc-dialog-close-btn">
    <mat-icon svgIcon="close" />
  </button>
</div>

<mat-dialog-content class="color-dark">
  <ng-content />
</mat-dialog-content>

<mat-dialog-actions class="p-6" align="start">
  <div class="flex justify-between w-full">
    <div>
      <button mat-raised-button
              data-test="e2e-entity-create-dialog-submit-btn"
              color="primary"
              type="button"
              class="mr-2"
              [disabled]="submitBtnDisabled"
              [apsBtnPending]="apsBtnPending"
              (click)="submitted.emit()">
        {{ submitBtnText }}
      </button>
      <button mat-stroked-button type="button" mat-dialog-close data-test="e2e-entity-create-dialog-cancel-btn" [disabled]="cancelDtnDisabled">Cancel</button>
    </div>
    <ng-content select="[extraControls]" />
  </div>
</mat-dialog-actions>
