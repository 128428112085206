import {AbstractControl} from '@angular/forms';
import {map, of, switchMap, timer} from 'rxjs';

import {isArraysAreEqual, ListResponse, Maybe, Pagination} from '@core/common';
import {
  Application,
  OrganizationCreateUpdateReq,
  OrganizationListItem, OrganizationListItemResponse,
  OrganizationResponse,
  OrganizationStatus
} from '@features/organizations/models';
import {OrganizationApiService} from '@features/organizations/services';
import {mapProjectOrganizationsToDto} from '@features/projects/utils';


export const isOrganizationsAreEqual = (o1: Partial<OrganizationResponse>, o2: Partial<OrganizationCreateUpdateReq>): boolean =>
  (o1?.organizationName === o2?.organizationName)
  && isArraysAreEqual(o1?.applicationIds!, o2?.applicationIds!)
  && (o1?.countryId === o2?.countryId)
  && (o1?.location === o2?.location)
  && (o1?.comment === o2?.comment);

export class ValidateNameNotTaken {
  static createValidator(apiService: OrganizationApiService, currentOrganization: Maybe<OrganizationResponse> = null) {
    return (control: AbstractControl<string>) => {
      if (currentOrganization?.organizationName) {
        if (currentOrganization?.organizationName === control.value) {
          return of(null);
        }
      }

      return timer(400).pipe(
        switchMap(() => apiService.searchByName(control.value)),
        map((result) => result?.data?.length ? { alreadyExists: true } : null)
      );
    };
  }
}

export class ValidateRefCodeNotTaken {

  static createValidator(apiService: OrganizationApiService, currentOrganization: Maybe<OrganizationResponse> = null) {
    return (control: AbstractControl<string>) => {
      if (currentOrganization?.referenceCode) {
        if (currentOrganization?.referenceCode === control.value) {
          return of(null);
        }
      }

      return timer(400)
        .pipe(
          switchMap(() => apiService.searchByRefCode(control.value)),
          map((result) => result?.data?.length ? { alreadyExists: true } : null)
        );
    };
  }
}

export const getOrganizationsFetchFn = (apiService: OrganizationApiService) => (pagination: Pagination) => {
  return apiService.getOrganizations(pagination, OrganizationStatus.active).pipe(
    map(response => {
      return {
        pagination: response.pagination,
        data: mapProjectOrganizationsToDto(response.data)
      };
    })
  );
};

export const getOrganizationFullLocation = (org?: Maybe<OrganizationListItem>): string => {
  if (org?.country) {
    return `${org.location ? `${org.location} - ` : ''}${org.country}`;
  } else {
    return org?.location ?? 'N/A';
  }
};

export const mapOrganizationsToListItems = (
  organizations: ListResponse<OrganizationListItemResponse>,
  applicationsMap: Map<number, Application>
): ListResponse<OrganizationListItem> => {
  return {
    pagination: organizations.pagination,
    data: organizations.data.map((listItem) => ({
      ...listItem,
      applications: listItem.applicationIds?.map((id) => applicationsMap.get(id)!)
    }))
  };
};
