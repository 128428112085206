<ng-container *apsNgLet="(pending$ | async) as pending">
  @if (isActiveOrganization()) {
    <button mat-stroked-button
            data-test="e2e-deactivate-btn"
            color="warn"
            class="w-100"
            [apsBtnPending]="pending"
            (click)="deactivate()">
      Deactivate Organization
    </button>
  } @else {
    <button mat-stroked-button
            data-test="e2e-activate-btn"
            color="accent"
            class="w-100"
            [apsBtnPending]="pending"
            (click)="activate()">
      Activate Organization
    </button>
  }
</ng-container>
