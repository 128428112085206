import {FormControl} from '@angular/forms';

import {GpsCoordinates, Maybe} from '@core/common';
import {UserBase} from '@features/users/models';

export interface ProjectForm {
  id: FormControl<Maybe<number>>;
  name: FormControl<string>;
  ocNumber: FormControl<string>;
  poNumber: FormControl<string>;
  distance: FormControl<Maybe<number>>;
  serialNumbers: FormControl<string[]>;
  description: FormControl<string>;
  apsComment: FormControl<string>;
  organization: FormControl<Maybe<number>>;
  technicians: FormControl<UserBase[]>;
  address: FormControl<string>;
  latitude: FormControl<Maybe<number>>;
  longitude: FormControl<Maybe<number>>;
  mappingType: FormControl<MappingType>;
  photo: FormControl<string>;
  progress: FormControl<Maybe<ProjectProgress>>;
  webhookUrl: FormControl<string>;
  leftTopLatitude: FormControl<Maybe<number>>;
  leftTopLongitude: FormControl<Maybe<number>>;
  rightBottomLatitude: FormControl<Maybe<number>>;
  rightBottomLongitude: FormControl<Maybe<number>>;
  zoom: FormControl<Maybe<number>>;
  routeValidation: FormControl<boolean>;
  positiveSectionLengthTolerance: FormControl<Maybe<number>>;
  negativeSectionLengthTolerance: FormControl<Maybe<number>>;
  mapType: FormControl<Maybe<string>>;
  fiberRouteFile: FormControl<Maybe<File>>;
  reportFileName: FormControl<Maybe<string>>;
}

export interface Project extends ProjectMapFieldsData {
  apsComment: string;
  createdBy: UserBase;
  createdOn: string;
  description: string;
  distance: number;
  guidId: string;
  id: number;
  isActive: boolean;
  lastLatitude: number;
  lastLongitude: number;
  lastMapSectionLatitude: number;
  lastMapSectionLongitude: number;
  lastUpdatedBy: UserBase;
  lastZoomLevel: number;
  modifiedOn: string;
  name: string;
  negativeSectionLengthTolerance: number;
  ocNumber: string;
  organization: ProjectOrganization;
  poNumber: string;
  poi: number;
  positiveSectionLengthTolerance: number;
  progress: ProjectProgress;
  reportFileName: string;
  routeValidation: boolean;
  serialNumbers: string[];
  technicians: UserBase[];
  userLastZoomLevel: number;
  webhookUrl: string;
}

export interface ProjectMapFieldsData {
  // project location fields
  address: string;
  latitude: number;
  longitude: number;
  mappingType: MappingType;
  photo: string;
  // offline area fields
  leftTopLatitude?: number;
  leftTopLongitude?: number;
  rightBottomLatitude?: number;
  rightBottomLongitude?: number;
  zoom?: number;
  mapType?: string;
}

export enum MappingType {
  notSelected = 'NOTSELECTED',
  map = 'GEOGRAPHICALREFERENCE',
  bluePrint = 'BLUEPRINTATTACHMENT',
}

export enum MappingTypeNumeric {
  notSelected = 0,
  geographicalReference = 1,
  blueprintAttachment = 2,
}

export interface ProjectOrganization {
  id: number;
  name?: string;
  status?: boolean;
  isActive?: boolean;
}

export enum ProjectProgress {
  new = 'NEW',
  inProgress = 'INPROGRESS',
  notStarted = 'NOTSTARTED',
  completed = 'COMPLETED',
}

export enum ProjectType {
  REGULAR = 'REGULAR',
  EVENT_REPORTING = 'EVENT_REPORTING',
}

export interface ProjectCreateReq {
  address: string;
  apsComment: string;
  description: string;
  distance: number;
  latitude: number;
  leftTopLatitude: number;
  leftTopLongitude: number;
  longitude: number;
  mapType: string;
  mappingType: string;
  name: string;
  negativeSectionLengthTolerance: number | undefined;
  ocNumber: string;
  organization: { id: number; },
  photo: string;
  poNumber: string;
  positiveSectionLengthTolerance: number | undefined;
  progress: string;
  rightBottomLatitude: number;
  rightBottomLongitude: number;
  routeValidation: boolean;
  serialNumbers: string[];
  technicians: { email: string }[],
  zoom: number;
}

export interface ProjectUpdateReq extends ProjectCreateReq {
  id: number;
}

export interface EventReportProjectCreateReq {
  name: string;
  description: string;
  gpsLocation?: GpsCoordinates;
  organizationId: number;
  mapType: MappingTypeNumeric;
  locationAddress: string;
  assignedTechnicians: { email: string }[];
}

export interface EventReportProjectUpdateReq extends EventReportProjectCreateReq {
  id: number;
  isActive: boolean;
  gpsLocation?: GpsCoordinates;
}

export interface OfflineMapArea {
  leftTopLatitude: Maybe<number>;
  leftTopLongitude: Maybe<number>;
  rightBottomLatitude: Maybe<number>;
  rightBottomLongitude: Maybe<number>;
  zoom?: number;
  mapType?: 'MAP' | 'SATELLITE';
}

export interface EventReportProjectForm {
  id: FormControl<Maybe<number>>;
  name: FormControl<string>;
  organization: FormControl<Maybe<number>>;
  description: FormControl<string>;
  address: FormControl<string>;
  latitude: FormControl<Maybe<number>>;
  longitude: FormControl<Maybe<number>>;
  blueprintFileName: FormControl<string>;
  technicians: FormControl<UserBase[]>;
  mappingType: FormControl<MappingType>;
  fiberRouteFile: FormControl<Maybe<File>>;
  webhookUrl: FormControl<string>;
  reportFileName: FormControl<string>;
  fiberRouteFileName: FormControl<string>;
}

export interface EventReportProjectFormRawValue {
  organization: number;
  id: number;
  name: string;
  description: string;
  address: string;
  latitude: number;
  webhookUrl: string;
  longitude: number;
  technicians: UserBase[];
  mappingType: MappingType;
  fiberRouteFileName?: string;
}

export interface LastMapLocation extends GpsCoordinates {
  zoom: number;
  id: number;
}

export interface EventReportProject {
  id: number;
  name: string;
  mapType: MappingTypeNumeric;
  description: string;
  webhookUrl: string;
  blueprintFileName: string;
  fiberRouteFileName: string;
  gpsLocation: Maybe<GpsCoordinates & { id: number }>;
  isActive: boolean;
  lastMapPositionLocation: LastMapLocation;
  locationAddress: string;
  createdOn: string;
  modifiedOn: string;
  modifiedBy: UserBase;
  createdBy: UserBase;
  assignedTechnicians: UserBase[];
  organization: ProjectOrganization;
}

export interface EventReportProjectListEntry {
  assignedTo: UserBase[];
  eventsCount: number;
  createdBy: string;
  id: number;
  locationAddress: string;
  modified: string;
  name: string;
  organizationName: string;
  organizationStatus: string;
  status: boolean;
}
