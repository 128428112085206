import {Maybe} from '@core/common';
import {UserProfile} from '@features/users/models';

export interface UserInfoState {
  userProfile: Maybe<UserProfile>;
  keycloakPermissions: Maybe<string[]>;
}

export const userInfoInitialState: UserInfoState = {
  userProfile: null,
  keycloakPermissions: null,
};
