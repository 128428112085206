import {createSelector} from '@ngrx/store';

import {AppState} from '../core.state';

export const selectUserInfoState = (state: AppState) => state.userInfo;

export const selectUserProfile = createSelector(
  selectUserInfoState,
  (state) => state?.userProfile,
);
