import {NgClass} from '@angular/common';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

import {ALL_AG_STATUSES_MAP} from '@shared/const';


@Component({
  selector: 'aps-ag-cell-status',
  template: `
    <div class="flex items-center">
      <mat-icon class="pointer-events-none"
                data-test="e2e-ag-cell-status"
                [svgIcon]="statusMap[value]?.icon"
                [ngClass]="statusMap[value]?.cssClass" />
    </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIcon,
    NgClass
  ]
})
export class AgCellStatusComponent implements ICellRendererAngularComp {
  value!: string;
  statusMap = ALL_AG_STATUSES_MAP;

  agInit(params: ICellRendererParams): void {
    this.value = params.valueFormatted ? params.valueFormatted : params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    this.value = params.valueFormatted ? params.valueFormatted : params.value;
    return false;
  }

}
