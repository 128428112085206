import {Maybe} from '@core/common';
import {Application, Country} from '@features/organizations/models';

export interface StaticDataState {
  countries: Maybe<Country[]>;
  applications: Maybe<Application[]>;
}

export const staticDataInitialState: StaticDataState = {
  countries: null,
  applications: null
};
