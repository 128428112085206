import {createActionGroup, emptyProps} from '@ngrx/store';

import {Application, Country} from '@features/organizations/models';

export const StaticDataActions = createActionGroup({
  source: 'Static Data',
  events: {
    'Load static data': emptyProps(),
    'Load static data success': (countries: Country[], applications: Application[]) => ({
      countries,
      applications,
    }),
    'Load static data failure': emptyProps(),
  }
});
