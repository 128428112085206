import {createReducer, on} from '@ngrx/store';
import {produce} from 'immer';

import {StaticDataActions} from './static-data.actions';
import {staticDataInitialState} from './static-data.state';

export const staticDataReducer = createReducer(
    staticDataInitialState,
    on(StaticDataActions.loadStaticDataSuccess, (state, { countries, applications }) =>
      produce(state, (draft) => {
        draft.countries = countries;
        draft.applications = applications;
      })
    )
  )
;

