<div class="bg-super-light-gray p-6 text-sm rounded-lg mt-2">

  <div class="flex flex-row w-full mb-2">
    <div class="basis-32">Created By</div>
    <div class="basis-auto flex items-center">
      <div class="font-medium" data-test="e2e-history-created-by">{{ createdBy | apsFullName | apsValueOrDefault }}</div>
      <div class="ml-10 text-xs" data-test="e2e-history-created-by-role">{{ createdBy?.role | apsValueOrDefault }}</div>
    </div>
  </div>

  <div class="flex flex-row w-full mb-2">
    <div class="basis-32">Created</div>
    <div class="basis-auto flex items-center">
      <mat-icon class="color-medium size-sm" svgIcon="calendar-range" />
      <div class="ml-2 font-medium" data-test="e2e-history-created-at">{{ createdDate | date:dateFormat }}</div>
    </div>
  </div>

  <div class="flex flex-row w-full mb-2">
    <div class="basis-32">Modified By</div>
    <div class="basis-auto flex items-center">
      <div class="font-medium" data-test="e2e-history-updated-by">{{ modifiedBy | apsFullName | apsValueOrDefault }}</div>
      <div class="ml-10 text-xs" data-test="e2e-history-updated-by-role">{{ modifiedBy?.role }}</div>
    </div>
  </div>

  <div class="flex flex-row w-full">
    <div class="basis-32">Modified</div>
    <div class="basis-auto flex items-center">
      <mat-icon class="color-medium size-sm" svgIcon="calendar-range" />
      <div class="ml-2 font-medium" data-test="e2e-history-updated-at">{{ lastUpdatedDate | date: dateFormat | apsValueOrDefault }}</div>
    </div>
  </div>
</div>
