<div class="ag-grid-wrapper">
  <ag-grid-angular
    class="ag-theme-aps-grid with-pagination"
    data-test="e2e-ag-grid"
    [columnDefs]="columnDefs"
    [rowData]="rowData$ | async"
    [gridOptions]="gridOptions"
    [rowSelection]="'multiple'"
    [animateRows]="true"
    [overlayNoRowsTemplate]="noRowsTemplate"
    [loading]="state.pending$ | async"
    [loadingOverlayComponent]="loadingOverlayComponent"
    [enableBrowserTooltips]="true"
    [suppressRowDrag]="true"
    [suppressMovableColumns]="true"
    (sortChanged)="onSortChanged($event)"
    (gridSizeChanged)="onResize($event)"
    (gridReady)="onGridReady($event)"
    (rowClicked)="rowSingleClicked.emit($event)"
    (rowDoubleClicked)="rowDoubleClicked.emit($event)"
  />
  <mat-paginator [length]="total"
                 [pageSize]="pageSize"
                 [showFirstLastButtons]="true"
                 [pageSizeOptions]="pageSizeOptions"
                 [disabled]="state.pending$ | async"
                 (page)="paginationChanged.emit($event)"
                 data-test="e2e-pagination"
                 aria-label="Select page" />
</div>
