import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

import {ProjectProgress} from '@features/projects/models';
import {StatusComponent} from '@shared/components';
import {StatusType} from '@shared/models';
import {TruncatePipe} from '@shared/pipes';

@Component({
  selector: 'aps-ag-cell-project-name',
  template: `
    <div class="flex items-center" data-test="e2e-ag-cell-project-name">
      <span class="mr-1">{{ value | apsTruncate: [truncate] }}</span>
      <aps-status [type]="progressToStatusTypeMap[progress]">{{ progressToStatusNameMap[progress] }}</aps-status>
    </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TruncatePipe,
    StatusComponent
  ]
})
export class AgCellProjectNameComponent implements ICellRendererAngularComp {
  value!: string;
  progress!: ProjectProgress;
  truncate = 46;

  readonly progressToStatusTypeMap: Record<ProjectProgress, StatusType> = {
    [ProjectProgress.new]: 'success',
    [ProjectProgress.inProgress]: 'info',
    [ProjectProgress.notStarted]: 'neutral',
    [ProjectProgress.completed]: 'grey'
  };

  readonly progressToStatusNameMap: Record<ProjectProgress, string> = {
    [ProjectProgress.new]: 'New',
    [ProjectProgress.inProgress]: 'In Prog.',
    [ProjectProgress.notStarted]: 'Not Started',
    [ProjectProgress.completed]: 'Completed'
  };

  agInit(params: ICellRendererParams & { truncate?: number }): void {
    this.value = params.valueFormatted ? params.valueFormatted : params.value;
    this.progress = params?.data?.progress;
    this.truncate = params.truncate ?? this.truncate;
  }

  refresh(params: ICellRendererParams & { truncate?: number }): boolean {
    this.value = params.valueFormatted ? params.valueFormatted : params.value;
    this.progress = params?.data?.progress;
    this.truncate = params.truncate ?? this.truncate;
    return false;
  }
}


